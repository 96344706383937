import logo from './logo.svg';
import './App.css';
import axios from 'axios'
import React, { useState, useEffect} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './components/PaymentForm.js';
import Slider from "react-slick";
import Cookies from 'js-cookie'




//---COMPONENTS---------------------------------------------
import Cart from './components/Cart.js'
import Merch from './components/Merch.js'
import Header from './components/Header.js'
import Gallery from './components/Gallery.js'
import Footer from './components/Footer.js'
import Music from './components/music.js'
//---Styles---------------------------------------------
import './styles/merch.css'
import './styles/header.css'
import './styles/gallery.css'
import './styles/cart.css'
import './styles/paymentForm.css'
import './styles/footer.css'
import './styles/music.css'

function App(){
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [amount, setAmount] = useState(20); // For example purposes
  const [showCart, setShowCart] = useState(true);

  const getCookie = async () => {
    axios.defaults.withCredentials = true; 
    const csrf = await axios.get("https://sh-backend-943051842194.northamerica-northeast2.run.app/api/get-csrf",
        {withCredentials: true},
    );
    //const csrf = await axios.get("http://localhost:8000/api/get-csrf");
    const csrfToken = csrf.data['csrf_token']; 
    Cookies.set('csrftoken', csrfToken, {secure: true, sameSite: 'None'});
    //console.log(document.cookie);
    
    axios.defaults.headers.common['X-Csrftoken'] = Cookies.get('csrftoken');
    axios.defaults.xsrfHeaderName = "X-Csrftoken";
    axios.defaults.xsrfCookieName = "csrftoken";


  };

  getCookie();

  const stripePromise = loadStripe('pk_test_51QEF9RLZmveBEED0GyHYLqDV4M9BDPlFerN8dDSAjZNzgNnv0QirCNoeCPwRR3DuW4bHJfs1v6hcIZLKlcJg6WIY0048d89Y0t');

  const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    });

  useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        setAmount((getTotalPrice() * 1.13).toFixed(2));
        if (cartItems.length > 0){
          setShowCart(true)
        } else {
          setShowCart(false); 
          setShowPaymentForm(false);
        }

    }, [cartItems]);

  function addToCart(product) {
      var size = document.getElementById("size_"+product.id).value
      const exists = cartItems.find(item => item.id === product.id && item.size === size);
      
      if (exists) {
            setCartItems(
                cartItems.map(item =>
                    item.id === product.id && item.size === size? { ...item, quantity: item.quantity + 1} : item
                )
            );
          } else {

          
      setCartItems([...cartItems, { ...product, quantity: 1, size: size }]);
          }
    }

  const removeFromCart = (product) => {
        setCartItems(cartItems.filter(item => item.id !== product.id || item.size !== product.size));
    };

  const getTotalPrice = () => {
      return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  useEffect(() => {
    axios.get('https://sh-backend-943051842194.northamerica-northeast2.run.app/api/hello')
    //axios.get('http://127.0.0.1:8000/api/hello')
      .then(response => {
        setItems(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(true);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }
  if (error) return <p>{error}</p>;

  return (
    <div>
        <Header></Header>
        <Gallery></Gallery>
        <Music></Music>
        <Merch 
          items={items} 
          addToCart={addToCart}/>
        <div className="cartWrapper">
          {showCart ? <Cart 
            cartItems={cartItems}
            removeFromCart={removeFromCart}
            amount={amount}
            getTotalPrice={getTotalPrice}
            setShowPaymentForm={setShowPaymentForm}
          /> : <div></div>}
          <Elements stripe={stripePromise}>
            <PaymentForm
              amount={amount}
              cartItems={cartItems}
              showPaymentForm={showPaymentForm}
              setShowPaymentForm={setShowPaymentForm}
            />
          </Elements>
        </div>
        <Footer></Footer> 
    </div> 
  );
}

export default App;
